const Input = (props: {
  type: string;
  placeholder: string;
  name: string;
  textarea: boolean;
}) => {
  return (
    <>
      {!props.textarea ? (
        <input
          type={props.type}
          name={props.name}
          className="bg-transparent border-b-2 border-primary focus:border-b-2 focus:border-accent-content focus:outline-none my-3"
          placeholder={props.placeholder}
          required
        />
      ) : (
        <textarea
          name={props.name}
          className="bg-transparent border-b-2 border-primary focus:border-b-2 focus:border-accent-content focus:outline-none my-3"
          placeholder={props.placeholder}
          required
        />
      )}
    </>
  );
};

export { Input };
