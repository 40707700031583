import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";

export const AboutMe = () => {
    return (
        <Element name="about-me">
            <div
                id="aboutMe"
                className="flex flex-col justify-center px-4 items-center h-[100vh] snap-always snap-center"
            >
                <div className="w-full mb-5">
                    <ScrollAnimation animateIn="backInDown">
                        <h3 className="text-[2.5rem] font-black text-center my-5 ml-5">Who's Frank Uhal?</h3>
                    </ScrollAnimation>
                </div>
                <div
                    className="bg-gradient-to-r from-secondary to-accent animate-wavy-borders md:w-[75%] md:h-[40%] text-center p-10 flex flex-col justify-center">
                    <ScrollAnimation animateIn="backInUp">
                        <p className="text-center text-lg">
                            I'm a software developer living in Prague, CZ. I love developing new software.
                            I'm a full-stack developer with more experience in Typescript, mostly
                            Next.js and React on frontend and Node.js with Express or Nest.js framework
                            on backend. I'm more proficient with frontend but I love improving my
                            knowledge in any area enjoy the process of resolving the issue hence I managed
                            to pick up backend throughout my career as well.
                        </p>
                    </ScrollAnimation>
                </div>
            </div>
        </Element>
    );
};
