import { Link } from 'react-scroll';

const Navbar = () => {

    return (
        <nav className="navbar bg-primary text-primary-content rounded-xl md:w-[98%] mx-auto my-2">
            <div className="navbar-start">
                <p className="ml-2 uppercase text-2xl hover:font-black">
                    Frank Uhal
                </p>
            </div>

            <div className="navbar-end">
                {/* Mobile dropdown */ }
                <div className="dropdown md:hidden">
                    <label tabIndex={ 0 } className="btn btn-ghost btn-circle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h7"
                            />
                        </svg>
                    </label>
                    <ul
                        tabIndex={ 0 }
                        className="menu dropdown-content shadow rounded-box w-32 ml-[-100px] text-bold capitalize bg-primary"
                    >
                        <li>
                            <Link to='about-me' smooth={ true } duration={ 500 }>About me</Link>
                        </li>
                        <li>
                            <Link to="my-projects" smooth={ true } duration={ 500 }>My projects</Link>
                        </li>
                        <li>
                            <Link to="contact" smooth={ true } duration={ 500 }>Contact</Link>
                        </li>
                    </ul>
                </div>
                {/* Desktop Menu */ }
                <ul className="hidden md:inline-flex">
                    <li className="mx-2 cursor-pointer hover:text-secondary">
                        <Link to='about-me' smooth={ true } duration={ 500 }>About me</Link>
                    </li>
                    <li className="mx-2 cursor-pointer hover:text-secondary">
                        <Link to="my-projects" smooth={ true } duration={ 500 }>My projects</Link>
                    </li>
                    <li className="mx-2 cursor-pointer hover:text-secondary">
                        <Link to="contact" smooth={ true } duration={ 500 }>Contact</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;