import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin, faTelegram, } from "@fortawesome/free-brands-svg-icons";
import { db } from "../firebase";
import { child, push, ref, update } from "firebase/database";
import { Input } from "../components/Input";
import { useState } from "react";
import { Element } from "react-scroll";

const Contact = () => {
    const [sentSuccess, setSentSuccess] = useState<boolean>(false);

    const socialsRedirect = (e: any) => {
        const social = e.target.name;

        switch (social) {
            case "telegram":
                window.open("https://t.me/frvnkhl", "_blank")?.focus();
                break;
            case "linkedIn":
                window
                    .open("https://www.linkedin.com/in/frank-uhal-9b117710b", "_blank")
                    ?.focus();
                break;
            case "github":
                window.open("https://github.com/frvnkhl", "_blank")?.focus();
                break;
            default:
                break;
        }
    };

    const handleFormSubmission = (e: any) => {
        e.preventDefault();
        const formData = {
            name: e.target[0].value,
            email: e.target[1].value,
            message: e.target[2].value,
        };
        setSentSuccess(true);
        (document.getElementById('submitForm') as HTMLFormElement).reset();
        const newFormSubmission = push(child(ref(db), "formData")).key;

        const updates: any = {};
        updates["/formData/" + newFormSubmission] = formData;

        return update(ref(db), updates);
    };

    return (
        <Element name="contact">
            <div className="h-full md:h-[100vh]" id="contact">
                <div className="md:flex h-full items-center my-10 md:my-5">
                    <div className="w-full md:w-[40%] flex flex-col justify-center items-center my-10 md:my-5">
                        <h3 className="my-5 text-2xl">Connect with me via socials</h3>
                        <button
                            className="btn btn-active bg-[#179CDE] text-white hover:bg-[#0088CC] my-3"
                            name="telegram"
                            onClick={ socialsRedirect }
                        >
                            <FontAwesomeIcon className="h-6 mr-2" icon={ faTelegram }/>
                            Telegram
                        </button>
                        <button
                            className="btn btn-active bg-[#1f1f1f] text-white hover:bg-[#0d1117] my-3"
                            name="github"
                            onClick={ socialsRedirect }
                        >
                            <FontAwesomeIcon className="h-6 mr-2" icon={ faGithub }/>
                            Github
                        </button>
                        <button
                            className="btn btn-active bg-[#0077b5] text-white hover:bg-[#005e93] my-3"
                            name="linkedIn"
                            onClick={ socialsRedirect }
                        >
                            <FontAwesomeIcon className="h-6 mr-2" icon={ faLinkedin }/>
                            LinkedIn
                        </button>
                    </div>
                    <div
                        className="w-full md:w-[60%] my-10 md:my-5 md:p-5 flex flex-col justify-center items-center bg-gradient-to-r from-secondary to-accent animate-wavy-borders h-[55%]">
                        <h3 className="my-3 text-2xl">Write me a message</h3>
                        <form
                            className="flex flex-col w-[85%] md:w-[75%]"
                            onSubmit={ handleFormSubmission }
                            id="submitForm"
                        >
                            { sentSuccess && (
                                <div className="alert alert-success shadow-lg">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="stroke-current flex-shrink-0 h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>Message sent successfully!</span>
                                    </div>
                                </div>
                            ) }
                            <Input
                                type="text"
                                name="name"
                                placeholder="Name"
                                textarea={ false }
                            />
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                textarea={ false }
                            />
                            <Input
                                type=""
                                name="message"
                                placeholder="Your message"
                                textarea={ true }
                            />
                            <button className="btn btn-neutral mx-auto m-3" type="submit">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Element>
    );
};

export { Contact };
