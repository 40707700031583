// import { ProjectDetail } from "../components/ProjectDetail";
// import { Project, projects } from "../data/projectsData";
import { Element } from "react-scroll";
import { ProjectList } from "../components/ProjectList";

const Projects = () => {

    return (
        <Element name="my-projects">
            <div
                className="flex flex-col justify-center items-center mt-5 h-full md:h-[100vh] snap-always snap-center w-full px-4"
                id="myProjects"
            >
                <h2 className="text-3xl font-bold">My previous work</h2>
                <div className="w-full md:w-[75%]">
                    <ProjectList/>
                </div>
            </div>
        </Element>
    );
};

export { Projects };
