import cbdpero from '../images/cbdpero.png'

export interface Project {
    image?: string;
    title: string;
    shortDesc: string;
    url?: string;
    personal: boolean;
    stack: string[];
}

export const projects: Project[] = [
    {
        title: 'Strafos',
        shortDesc: 'Czech startup company, developing a private jet reservation system. I work there as a full-stack developer. I have created some 3rd party integration on both, frontend and backend, worked on APIs and implementing the new features on both, frontend and backend.',
        personal: false,
        stack: ['react.js', 'gatsby.js', 'node.js', 'nest.js', 'postgreSQL', 'styled-components']
    },
    {
        title: 'Ars-povertica',
        shortDesc: 'Small personal project. A web for a new font for children with low level of literacy. I created client side fetched gallery and font previewer, similar to one on Google Fonts.',
        personal: false,
        stack: ['react.js', 'next.js', 'tailwind.css']
    },
    {
        title: 'Brokero',
        shortDesc: `Web for a virtual financial advisor. It's a web with the news and tips for investing. I implemented some new features such as graphs with the investments, implemented analytics and tracking events.`,
        personal: false,
        stack: ['react.js', 'next.js', 'sass']
    },
    {
        title: 'Newwork',
        shortDesc: 'German startup company developing an ERP system. I worked in a frontend team and developed couple of native app applications, developed reusable components and integrated 3rd party tools such as Monaco as a code editor.',
        personal: false,
        stack: ['react.js', 'next.js', 'graphQL', 'tailwind.css', 'jest']
    },
    {
        title: 'PPPS',
        shortDesc: `Slovak non-profit organisation developing a net of food banks in Slovakia. I created a presentational website in elementor.`,
        personal: false,
        stack: ['wordpress']
    },
    {
        image: cbdpero,
        title: 'CBDPero',
        shortDesc: 'CBDPero is an e-commerce store selling CBD vape pens. This is a multilingual and multicurrency webstore using wordpress and woocommerce.',
        url: 'https://cbdpero.com',
        personal: false,
        stack: ['wordpress', 'woocommerce']
    }
]
