import { Dispatch, SetStateAction } from "react";

const renderIntroInstall = (setInstallText: Dispatch<SetStateAction<any>>) => {
  setTimeout(
    () =>
      setInstallText((prevState: any) => {
        return { ...prevState, installText: "installing." };
      }),
    500
  );

  setTimeout(
    () =>
      setInstallText((prevState: any) => {
        return { ...prevState, installText: "installing.." };
      }),
    1000
  );

  setTimeout(
    () =>
      setInstallText((prevState: any) => {
        return { ...prevState, installText: "installing..." };
      }),
    1500
  );

  setTimeout(
    () =>
      setInstallText((prevState: any) => {
        return { ...prevState, completeText: "Frank Uhal - a developer" };
      }),
    2000
  );
};

export { renderIntroInstall };
