import { useEffect, useState } from "react";
import { renderIntroInstall } from "../animation/introTextRender";
import avatar from "../images/avatar.png";

export const Intro = () => {
  const [installText, setInstallText] = useState<any>({
    installText: "",
    completeText: "",
  });
  // const [experienceText, setExperienceText] = useState<string[]>([]);
  useEffect(() => {
    setInstallText((prevState: any) => {
      return { ...prevState, installText: "installing" };
    });
    renderIntroInstall(setInstallText);
  }, []);

  return (
    <div className="grid md:grid-cols-2 snap-always snap-center">
      <div className="flex align-middle items-center h-[80vh]">
        <div className="bg-gradient-to-r from-secondary to-accent h-[250px] w-[350px] animate-wavy-borders mx-auto">
          <img
            src={avatar}
            alt="Frank Uhal Avatar"
            className="h-[100%] w-[100%] object-contain mx-auto mt-[-100px]"
          />
          <div className="mockup-code">
            <pre data-prefix="$">
              <code>sudo install frank-uhal</code>
            </pre>
            <pre data-prefix=">" className="text-warning">
              <code>{installText.installText}</code>
            </pre>
            <pre data-prefix=">" className="text-success">
              <code className="transition duration-700 fade-in">
                {installText.completeText}
              </code>
            </pre>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center h-[80vh]">
        <div className="w-[100%] p-5 md:w-[75%]">
          <div className="mockup-code bg-primary">
            <pre data-prefix="$" className="text-accent">
              <code>sudo frank-uhal can-create</code>
            </pre>
            <pre data-prefix=">" className="text-primary-content">
              <code>Next.js full-stack apps</code>
            </pre>
            <pre data-prefix=">" className="text-primary-content">
              <code>MERN full-stack apps</code>
            </pre>
            <pre data-prefix=">" className="text-primary-content">
              <code>Single Page Applications</code>
            </pre>
            <pre data-prefix=">" className="text-primary-content">
              <code>Microservices</code>
            </pre>
          </div>
          <div className="mockup-code mt-10">
            <pre data-prefix="$" className="text-secondary-content">
              <code>sudo frank-uhal experience</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>JavaScript & TypeScript</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>React.js</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>Next.js</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>Node.js</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>Nest.js</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>MongoDB</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>MySQL</code>
            </pre>
            <pre data-prefix=">" className="text-primary">
              <code>PostgresSQL & TypeORM</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
