import { projects } from "../data/projectsData";
import { useState } from "react";
import clsx from "clsx";
import ScrollAnimation from "react-animate-on-scroll";

export const ProjectList = () => {
    const [activeProject, setActiveProject] = useState(0);


    return (
        <>
            <h2 className="my-4 text-center md:text-start">{ `Projects I worked on` }</h2>
            <div className="w-full flex flex-col md:flex-row my-6 h-auto items-stretch">
                <ul className="menu bg-primary flex-row md:flex-col md:min-w-52 justify-around rounded-box">
                    { projects.map((project, i) => (
                            <ScrollAnimation animateIn="backInLeft" key={ i }>
                                <h4
                                    onClick={ () => setActiveProject(i) }
                                    className={ clsx(['cursor-pointer text-primary-content',
                                        'hover:text-secondary',
                                        i === activeProject ? 'active text-secondary' : ''
                                    ]) }
                                >
                                    { project.title }
                                </h4>
                            </ScrollAnimation>
                        )
                    ) }
                </ul>
                <div className="w-full ml-4">
                    <div
                        className="w-full h-full my-10 md:my-5 md:p-5 flex flex-col justify-evenly text-center items-center bg-gradient-to-r from-secondary to-accent animate-wavy-borders">
                        <ScrollAnimation animateIn="backInRight">
                            <h2>{ projects[activeProject].title }</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="backInRight">
                            <p className="p-8">{ projects[activeProject].shortDesc }</p>
                        </ScrollAnimation>
                        <div className="w-full p-8">
                            <ScrollAnimation animateIn="backInRight">
                                <div className="divider">TECHNOLOGY</div>
                                <div className="flex flex-wrap justify-center">
                                    { projects[activeProject].stack.map(tech => <div
                                        className="badge badge-primary m-4">{ tech }</div>) }
                                </div>
                            </ScrollAnimation>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};