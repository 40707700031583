import React from 'react';
import { Intro } from "./sections/Intro";
import { AboutMe } from "./sections/AboutMe";
import { Projects } from "./sections/Projects";
import { Contact } from "./sections/Contact";
import Navbar from "./components/Navbar";
import { RiArrowUpSLine } from "react-icons/ri";
import { Link } from "react-scroll";

function App() {
    return (
        <div className="relative">
            <Navbar/>
            <Intro/>
            <AboutMe/>
            <Projects/>
            <Contact/>
            <button
                className="btn btn-accent btn-circle fixed right-6 border-transparent bottom-6 bg-gradient-to-r from-secondary to-accent hover:scale-125">
                <Link to='root' smooth={ true } duration={ 500 }><RiArrowUpSLine className="w-8 h-8"/></Link>
            </button>
        </div>
    );
}

export default App;
